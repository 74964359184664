import { IColumn, TooltipHost } from "@fluentui/react";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { CompaniesColumnHeader } from "components/pages/RequestsRMAPage/RMARequestPanel/CompaniesColumnHeader";
import { AppLanguage } from "constants/enums";
import { NotificationBrowseDto, NotificationDto, NotificationUserDto } from "generated-sources/openapi";
import loc from "localization";

export const listOfEventsColumn = (language: AppLanguage): IColumn[] => [
  {
    key: "listOfEvents",
    name: loc.configureNotifications.listOfEvents,
    fieldName: language === AppLanguage.English ? "nameEn" : "nameRu",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: NotificationDto) => (
      <TooltipHost content={language === AppLanguage.English ? item.nameEn : item.nameRu}>
        {language === AppLanguage.English ? item.nameEn : item.nameRu}
      </TooltipHost>
    ),
  }
];

export const usersListColumn = (
  title: string,
  updateSubscription?: (val: boolean, item: NotificationBrowseDto) => void,
): IColumn[] => [
  {
    key: "usersList",
    name: title,
    fieldName: "",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: NotificationUserDto) => {
      return (
        <div>{item.firstName} {item.lastName}</div>
      );
    }
  },
].concat(updateSubscription ? [
  {
    key: "companies",
    name: "",

    fieldName: "companies",
    minWidth: 125,
    maxWidth: 125,
    isResizable: true,
    onRenderHeader: () => (
      <CompaniesColumnHeader />
    ),
    onRender: (item: NotificationBrowseDto) => {
      return (
        <div className="flex-row">
          <BasicCheckbox
            checked={item.y}
            onChange={(checked) => updateSubscription(checked, { id: item.id, tn: item.tn, p: item.p, l: item.l, y: checked })}
          />
          <BasicCheckbox
            checked={item.p}
            onChange={(checked) => updateSubscription(checked, { id: item.id, tn: item.tn, p: checked, l: item.l, y: item.y })}
          />
          <BasicCheckbox
            checked={item.l}
            onChange={(checked) => updateSubscription(checked, { id: item.id, tn: item.tn, p: item.p, l: checked, y: item.y })}
          />
        </div>
      );
    },
  },
  // eslint-disable-next-line
] as any : []);
