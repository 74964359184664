import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { useApi, useHttp, useSelection } from "hooks";
import { listOfEventsColumn, usersListColumn } from "./config";
import { DefaultButton, SelectionMode } from "@fluentui/react";
import styles from "./styles.module.scss";
import { NotificationBrowseDto, NotificationDto, NotificationUserDto } from "generated-sources/openapi";
import { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "context/languageContext";
import { orderArrayBySorting } from "utils";
import { Sorting } from "constants/enums";

interface IConfigureNotificationsModalProps {
  onDismiss: () => void;
}

export const ConfigureNotificationsModal = (props: IConfigureNotificationsModalProps) => {
  const { loc, language } = useContext(LanguageContext);

  const notificationHttp = useHttp<NotificationBrowseDto[]>();
  const usersHttp = useHttp<NotificationUserDto[]>();
  const eventsHttp = useHttp<NotificationDto[]>();
  const subscribeHttp = useHttp({ showErrors: true });
  const unsubscribeHttp = useHttp({ showErrors: true });

  const [events, setEvents] = useState<NotificationDto[]>([]);
  const [users, setUsers] = useState<NotificationUserDto[]>([]);
  const [notifications, setNotification] = useState<NotificationBrowseDto[]>([]);

  const eventSelection = useSelection<NotificationDto>();
  const userSelection = useSelection<NotificationUserDto>();
  const enabledNotificationsSelection = useSelection<NotificationBrowseDto>();

  const { settingsNotificationsApi } = useApi();

  const loadNotification = () => {
    notificationHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsBrowseGet())
      .then((res) => {
        setNotification(res.data);
      });
  };

  const loadUsers = () => {
    usersHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsFilterUsersGet())
      .then((res) => {
        setUsers(res.data);
      });
  };

  const loadEvents = () => {
    eventsHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsFilterNotificationsGet())
      .then((res) => {
        setEvents(res.data);
      });
  };

  const subscribeUser = () => {
    subscribeHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsSubscribePost({
      subscribePayload: {
        id: eventSelection.selectedItem?.id,
        tn: userSelection.selectedItem?.tn
      }
    })).then((() => {
      loadNotification();
    }));
  };

  const unsubscribeUser = () => {
    subscribeHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsUnsubscribeDelete({
      unsubscribePayload: {
        id: enabledNotificationsSelection.selectedItem?.id,
        tn: enabledNotificationsSelection.selectedItem?.tn
      }
    })).then((() => {
      loadNotification();
    }));
  };

  const updateSubscription = (val: boolean, item: NotificationBrowseDto) => {
    subscribeHttp.request(() => settingsNotificationsApi.apiSettingsNotificationsUpdateSubscriptionPost({
      updateSubscriptionPayload: item
    })).then((() => {
      loadNotification();
    }));
  };

  useEffect(() => {
    loadNotification();
    loadUsers();
    loadEvents();
  }, []);

  const orderUsersAlphabetically = (items: NotificationUserDto[]) => {
    return orderArrayBySorting(items, {
      field: "firstName",
      type: Sorting.ASC
    });
  };

  const enabledNotifications = useMemo(() =>
    orderUsersAlphabetically(notifications.filter(el => el.id === eventSelection.selectedItem?.id && !el.isUserDeleted)),
  [notifications, eventSelection]);

  const sortedAndFilteredUsers = useMemo(() => orderUsersAlphabetically(users.filter(user => {
    return !enabledNotifications.some(notification => notification.tn === user.tn);
  })), [users, enabledNotifications]);

  return (
    <BasicModal
      isLoading={
        notificationHttp.isLoading ||
        usersHttp.isLoading ||
        eventsHttp.isLoading ||
        subscribeHttp.isLoading ||
        unsubscribeHttp.isLoading
      }
      title={loc.settings.configureNotifications}
      dismissButtonText={loc.buttons.close}
      onDismiss={props.onDismiss}
      additionalClassName={styles.modal}
      errors={notificationHttp.errorMessages}
    >
      <BasicModalContent>
        <div className={styles.elementsContainer}>
          <div className="scrollable-table-wrapper">
            <ScrollableTableWrapper noData={!events.length}>
              <Table
                columns={listOfEventsColumn(language)}
                items={events}
                selectionMode={SelectionMode.single}
                selection={eventSelection.selection}
              />
            </ScrollableTableWrapper>
          </div>
          <div className="scrollable-table-wrapper" style={{ minWidth: "420px" }}>
            <ScrollableTableWrapper noData={!events.length}>
              <Table
                columns={usersListColumn(loc.configureNotifications.usersForNotify, updateSubscription)}
                items={enabledNotifications}
                selectionMode={SelectionMode.single}
                selection={enabledNotificationsSelection.selection}
              />
            </ScrollableTableWrapper>
          </div>
          <div className={styles.buttonSection}>
            <DefaultButton
              iconProps={{ iconName: "ChevronLeftMed" }}
              onClick={subscribeUser}
              disabled={!userSelection.selectedItem || !eventSelection.selectedItem}
            />
            <DefaultButton
              iconProps={{ iconName: "ChevronRightMed" }}
              onClick={unsubscribeUser}
              disabled={!enabledNotificationsSelection.selectedItem}
            />
          </div>
          <div className="scrollable-table-wrapper">
            <ScrollableTableWrapper noData={!users.length}>
              <Table
                columns={usersListColumn(loc.configureNotifications.usersList)}
                items={sortedAndFilteredUsers}
                selectionMode={SelectionMode.single}
                selection={userSelection.selection}
              />
            </ScrollableTableWrapper>
          </div>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};