import {
  NOTIFICATIONS_PATH,
  REPORTS_PATH,
  SERVICE_PATH_FULL,
  DEFECTS_PAGE_PATH_FULL,
  SERIAL_NUMS_TRACKING_PAGE_PATH_FULL,
  MAINTAINABILITY_PAGE_PATH_FULL,
  NORMHOURS_PAGE_PATH_FULL,
  DEVICES_PATH_FULL,
  REQUESTS_RMA_PAGE_PATH_FULL,
  PERMISSIONS_RMA_PAGE_PATH_FULL,
  INVOICES_PATH_FULL,
  REPAIR_PLAN_PATH_FULL,
  WORKSHOP_PATH_FULL,
  CUSTOMERS_INFO_PATH_FULL,
  REPLACED_PARTS_REPORT_PATH_FULL,
  COMPLIANCE_WITH_DECLARED_DEFECTS_PATH_FULL,
  LICENSES_USED_PATH_FULL,
  DEVICES_NOT_SHIPPED_PATH_FULL,
  LIST_OF_COMPLAINTS_PATH_FULL,
  REPAIR_TIME_REPORT_PATH_FULL,
  QC_TIME_PATH_FULL,
  FULFILLMENT_OF_REPAIR_PLANS_PATH_FULL,
  UNPLANNED_DEVICES_PATH_FULL,
  DISTRIBUTOR_RATING_PATH_FULL,
  COST_OF_TRANSPORTATION_PATH_FULL,
  INCOME_FORECAST_PATH_FULL,
  DEVICES_SHIPPED_TO_REPAIR_PATH_FULL,
  RETURNS_REASONS_PATH_FULL,
  REPAIR_PRICE_PROSTAFF_PATH_FULL,
  GENERAL_REPORT_AVERAGE_REPAIR_TIME_PATH_FULL,
  CATEGORY_A_PATH_FULL,
  PAID_REPAIR_PATH_FULL,
  RETURN_LEVEL_PATH_FULL,
  DEVICE_ARRIVAL_TIME_REPORT_PATH_FULL,
  LIST_OF_COMPLAINTS_EXTERNAL_PATH_FULL,
  REPAIRED_RETURN_PAGE_PATH_FULL,
  INVOICE_NOTES_PATH_FULL,
  PROLONGED_DEVICES_PATH_FULL,
  SERVICE_CENTERS_DATA_UPLOAD_PATH_FULL,
  SERVICE_COSTS_PATH_FULL,
  ADDED_PARTS_PATH_FULL,
  SO_REPAIR_DATA_PATH_FULL,
  EQUIPMENT_PATH_FULL,
  UPGRADE_DEVICES_PATH_FULL,
  DISTRIBUTOR_DEVICES_PATH_FULL,
  REPAIR_TIME_ANALYSIS_PATH_FULL,
  FAQ_PATH,
  AVERAGE_REPAIR_TIME_PATH_FULL,
  EXCEEDING_REPAIRS_PATH_FULL,
  REPAIRED_RETURNS_PATH_FULL,
  SPARE_PARTS_TOTAL_SPENDING_UPGRADABLE_PATH_FULL,
  WORK_EXECUTED_PATH_FULL,
  INFORMER_NEWS_PATH_FULL,
  GENERAL_DAILY_REPORT_PATH_FULL,
  TIME_RATES_PATH_FULL,
  REPAIR_TIME_PAINTING_PATH_FULL,
  COMPLETENESS_PATH_FULL,
  RESERVE_FUND_PATH_FULL,
} from "constants/paths";
import { loc } from "localization";
import { IPermissions } from "utils";

export interface IMenuItem {
  key: string;
  name: string;
  url?: string;
  iconName?: string;
  disabled: boolean;
  underline?: boolean;
  newTab?: boolean;
  links?: IMenuItem[];
  style?: React.CSSProperties;
}

export const getMenuItems = (permissions: IPermissions): IMenuItem[] => [
  {
    key: "reference-books",
    name: loc.menu.referenceBooks,
    url: "/reference-books",
    iconName: "Dictionary",
    disabled: false,
    links: [
      {
        key: "defects",
        name: loc.menu.defects,
        url: DEFECTS_PAGE_PATH_FULL,
        disabled: !permissions.defectsPage,
      },
      {
        key: "tracking",
        name: loc.menu.tracking,
        url: SERIAL_NUMS_TRACKING_PAGE_PATH_FULL,
        disabled: !permissions.serialNumsTrackingPage,
      },
      {
        key: "maintainability",
        name: loc.menu.maintainability,
        url: MAINTAINABILITY_PAGE_PATH_FULL,
        disabled: !permissions.maintainabilityPage,
      },
      {
        key: "works",
        name: loc.menu.workExecuted,
        url: WORK_EXECUTED_PATH_FULL,
        disabled: !permissions.workExecutedPermission,
      },
      {
        key: "invoice-notes",
        name: loc.menu.invoiceNotes,
        url: INVOICE_NOTES_PATH_FULL,
        disabled: false,
      },
      {
        key: "groups-reference-books",
        name: loc.menu.groupBooks,
        disabled: false,
        links: [
          {
            key: "norm-hours",
            name: loc.menu.normHours,
            url: NORMHOURS_PAGE_PATH_FULL,
            disabled: !permissions.normHoursPage,
          },
          {
            key: "items",
            name: loc.menu.devices,
            url: DEVICES_PATH_FULL,
            disabled: !permissions.devicesPage
          }
        ]
      },
      {
        key: "informer-news",
        name: loc.menu.informer,
        url: INFORMER_NEWS_PATH_FULL,
        disabled: false,
      },
      {
        key: "time-rates",
        name: loc.menu.timeRates,
        url: TIME_RATES_PATH_FULL,
        disabled: false,
      },
      {
        key: "completeness",
        name: loc.menu.completeness,
        url: COMPLETENESS_PATH_FULL,
        disabled: false,
      },
    ],
  },
  {
    key: "operations",
    name: loc.menu.operations,
    url: "/operations",
    iconName: "DocumentSet",
    disabled: false,
    links: [
      {
        key: "requests-rma",
        name: loc.menu.requestsRMA,
        url: REQUESTS_RMA_PAGE_PATH_FULL,
        disabled: !permissions.requestsRMAPage,
        iconName: "AccountActivity",
      },
      {
        key: "permissions-rma",
        name: loc.menu.permissionsRMA,
        url: PERMISSIONS_RMA_PAGE_PATH_FULL,
        disabled: !permissions.permissionsRMAPage,
        iconName: "Assign",
      },
      {
        key: "invoices",
        name: loc.menu.invoices,
        url: INVOICES_PATH_FULL,
        disabled: !permissions.invoicesPage,
        iconName: "ChangeEntitlements",
      },
      {
        key: "service",
        name: loc.menu.service,
        url: SERVICE_PATH_FULL,
        disabled: !permissions.servicePage,
        iconName: "IssueTracking",
        // links: [],
      },
      {
        key: "repair-plan",
        name: loc.menu.repairPlan,
        url: REPAIR_PLAN_PATH_FULL,
        disabled: !permissions.repairPlanPage,
        iconName: "CalendarSettings",
      },
      {
        key: "workshop",
        name: loc.menu.workshop,
        url: WORKSHOP_PATH_FULL,
        disabled: false,
        iconName: "CRMServices",
      },
      {
        key: "customers-info",
        name: loc.menu.customersInfo,
        url: CUSTOMERS_INFO_PATH_FULL,
        disabled: false,
        iconName: "People",
      },
      // {
      //   key: "replacement-devices",
      //   name: loc.menu.summaryOfReplacements,
      //   url: REPLACEMENT_DEVICES_PATH_FULL,
      //   disabled: false,
      //   iconName: "DatabaseSync",
      // },
      {
        key: "prolonged-licenses",
        name: loc.menu.prolongedLicenses,
        url: PROLONGED_DEVICES_PATH_FULL,
        disabled: false,
        iconName: "UnstackSelected",
      },
      {
        key: "centers-data-upload",
        name: loc.menu.distributorsServiceCentersDataUpload,
        url: SERVICE_CENTERS_DATA_UPLOAD_PATH_FULL,
        disabled: !permissions.serviceCentersDatauploadPage,
        iconName: "BulkUpload",
      },
      {
        key: "equipment",
        name: loc.menu.equipment,
        url: EQUIPMENT_PATH_FULL,
        iconName: "DataManagementSettings",
        disabled: false,
      },
      {
        key: "reserve-fund",
        name: loc.menu.reserveFund,
        url: RESERVE_FUND_PATH_FULL,
        iconName: "ReservationOrders",
        disabled: false,
      },
    ],
  },
  {
    key: "reports",
    name: loc.menu.reports,
    url: REPORTS_PATH,
    iconName: "ReportDocument",
    disabled: false,
    links: [...[
      {
        key: "regular-reports",
        name: loc.menu.regularReports,
        disabled: false,
        links: [
          {
            key: "average-repair-time",
            name: loc.menu.averageTimeRepair,
            url: AVERAGE_REPAIR_TIME_PATH_FULL,
            disabled: false,
          },
          {
            key: "exceeding-repairs",
            name: loc.menu.exceedingRepairs,
            url: EXCEEDING_REPAIRS_PATH_FULL,
            disabled: false,
          },
          {
            key: "repaired-returns",
            name: loc.menu.repeatedReturns,
            url: REPAIRED_RETURNS_PATH_FULL,
            disabled: false,
          },
          {
            key: "general-daily-report",
            name: loc.menu.dailyGeneralReport,
            url: GENERAL_DAILY_REPORT_PATH_FULL,
            disabled: false,
          },
        ],
      },
      {
        key: "replaced-parts-report",
        name: loc.menu.replacedPartsReport,
        url: REPLACED_PARTS_REPORT_PATH_FULL,
        disabled: false,
      },
      {
        key: "compliance-with-declared-defect",
        name: loc.menu.complianceWithDeclaredDefects,
        url: COMPLIANCE_WITH_DECLARED_DEFECTS_PATH_FULL,
        disabled: false,
      },
      {
        key: "licenses-used",
        name: loc.menu.licensesUsed,
        url: LICENSES_USED_PATH_FULL,
        disabled: false,
      },
      {
        key: "devices-not-shipped",
        name: loc.menu.devicesNotShipped,
        url: DEVICES_NOT_SHIPPED_PATH_FULL,
        disabled: false,
      },
      {
        key: "list-of-complaints",
        name: loc.menu.listOfComplaints,
        url: LIST_OF_COMPLAINTS_PATH_FULL,
        disabled: false,
      },
      {
        key: "repair-time",
        name: loc.menu.repairTime,
        url: REPAIR_TIME_REPORT_PATH_FULL,
        disabled: false,
      },
      {
        key: "qc-time",
        name: loc.menu.qcTime,
        url: QC_TIME_PATH_FULL,
        disabled: false,
      },
      {
        key: "fulfillment-of-rep-plans",
        name: loc.menu.fulfillmentOfRepairPlans,
        url: FULFILLMENT_OF_REPAIR_PLANS_PATH_FULL,
        disabled: false,
      },
      {
        key: "unplanned-devices",
        name: loc.menu.unplannedDevices,
        url: UNPLANNED_DEVICES_PATH_FULL,
        disabled: false,
      },
      {
        key: "distributor-rating",
        name: loc.menu.distributorRating,
        url: DISTRIBUTOR_RATING_PATH_FULL,
        disabled: false,
      },
      {
        key: "cost-of-transportation",
        name: loc.menu.costOfTransportation,
        url: COST_OF_TRANSPORTATION_PATH_FULL,
        disabled: false,
      },
      {
        key: "income-forecast",
        name: loc.menu.incomeForecast,
        url: INCOME_FORECAST_PATH_FULL,
        disabled: false,
      },
      {
        key: "devices-shipped-to-repair",
        name: loc.menu.devicesShippedToRepair,
        url: DEVICES_SHIPPED_TO_REPAIR_PATH_FULL,
        disabled: false,
      },
      {
        key: "returns-reasons",
        name: loc.menu.returnsReasons,
        url: RETURNS_REASONS_PATH_FULL,
        disabled: false,
      },
      {
        key: "repair-price-prostaff",
        name: loc.menu.paidRepairProStaff,
        url: REPAIR_PRICE_PROSTAFF_PATH_FULL,
        disabled: false,
      },
      {
        key: "general-report-average-repair-time",
        name: loc.menu.generalReport,
        url: GENERAL_REPORT_AVERAGE_REPAIR_TIME_PATH_FULL,
        disabled: false
      },
      {
        key: "category-a",
        name: loc.menu.categoryA,
        url: CATEGORY_A_PATH_FULL,
        disabled: false
      },
      {
        key: "paid-repair",
        name: loc.menu.paidRepair,
        url: PAID_REPAIR_PATH_FULL,
        disabled: false
      },
      {
        key: "return-level",
        name: loc.menu.returnLevel,
        url: RETURN_LEVEL_PATH_FULL,
        disabled: false
      },
      {
        key: "device-arrival-time-report",
        name: loc.menu.deviceArrivalTime,
        url: DEVICE_ARRIVAL_TIME_REPORT_PATH_FULL,
        disabled: false
      },
      {
        key: "list-of-external-complaints",
        name: loc.menu.listOfComplaintsExternal,
        url: LIST_OF_COMPLAINTS_EXTERNAL_PATH_FULL,
        disabled: false
      },
      {
        key: "repaired-returns",
        name: loc.menu.repairedReturns,
        url: REPAIRED_RETURN_PAGE_PATH_FULL,
        disabled: false
      },
      {
        key: "service-costs",
        name: loc.menu.serviceCost,
        url: SERVICE_COSTS_PATH_FULL,
        disabled: false
      },
      {
        key: "added-parts",
        name: loc.menu.addedPartsPage,
        url: ADDED_PARTS_PATH_FULL,
        disabled: false,
      },
      {
        key: "so-repair-data",
        name: loc.menu.soRepairData,
        url: SO_REPAIR_DATA_PATH_FULL,
        disabled: false,
      },
      {
        key: "upgrade-devices",
        name: loc.menu.upgradeDevices,
        url: UPGRADE_DEVICES_PATH_FULL,
        disabled: false,
      },
      {
        key: "distributor-devices",
        name: loc.menu.distributorDevices,
        url: DISTRIBUTOR_DEVICES_PATH_FULL,
        disabled: false,
      },
      {
        key: "repair-time-analysis",
        name: loc.menu.repairTimeAnalysisReport,
        url: REPAIR_TIME_ANALYSIS_PATH_FULL,
        disabled: false,
      },
      {
        key: "spare-parts-spending-upgradable",
        name: loc.menu.sparePartsUpgradableReport,
        url: SPARE_PARTS_TOTAL_SPENDING_UPGRADABLE_PATH_FULL,
        disabled: false,
      },
      {
        key: "repair-time-painting",
        name: loc.menu.paintingTime,
        url: REPAIR_TIME_PAINTING_PATH_FULL,
        disabled: false,
      },
    ]].sort((el1, el2) => el2.name > el1.name ? -1 : 1),
  },
  {
    key: "notifications",
    name: loc.menu.notifications,
    url: NOTIFICATIONS_PATH,
    iconName: "Mail",
    disabled: false,
  },
  {
    key: "faq",
    name: loc.menu.faqPage,
    url: FAQ_PATH,
    iconName: "ReceiptTentative",
    disabled: false,
  }
];
