import { useContext, useState, useEffect, useMemo } from "react";
import { AxiosResponse } from "axios";
import { filter, map } from "lodash-es";
import { DefaultButton, SelectionMode } from "@fluentui/react";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { CommandWrapper } from "components/common/CommandWrapper";
import { LanguageContext } from "context/languageContext";
import { AppLanguage, CommandbarCommands } from "constants/enums";
import { BlameBrowseDto, DeviceDefectsDto } from "generated-sources/openapi";
import loc from "localization";
import { useApi, useHttp, useMultiSelection } from "hooks";
import { DeleteModal } from "../../DeleteModal";
import { getColumns } from "./config";

export interface IDefectsAndReasonsProps {
  disabledAddButton: boolean;
  basicPayload: { firmId: number, deviceId: number };
  defects: DeviceDefectsDto[];
  setDefects: (callback: (defects: DeviceDefectsDto[]) => DeviceDefectsDto[]) => void;
  setActiveCommand: (command: CommandbarCommands) => void;
  setWarningMessage: (message: string) => void;
  updateState: () => void;
  onRowDataUpdate?: () => void;
  readonly?: boolean;
};

export const DefectsAndReasons = (props: IDefectsAndReasonsProps) => {
  const { language } = useContext(LanguageContext);
  const { blamesApi, devicesApi } = useApi();
  const blamesHttp = useHttp<BlameBrowseDto[]>();
  const devicesHttp = useHttp();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [blames, setBlames] = useState<BlameBrowseDto[]>([]);
  const { selection, selectedItems } = useMultiSelection<DeviceDefectsDto>(props.defects);
  const haveChekedDefects = useMemo(() => filter(props.defects, (item: DeviceDefectsDto) => item.osn), [props.defects]);
  const [tableIsOpen, setTableIsOpen] = useState<boolean>(false);
  const onChange = (item: DeviceDefectsDto) => {
    props.setDefects(defects => map(defects, defect => defect.defId === item.defId ? item : defect));
  };

  const onBlur = (item: DeviceDefectsDto) => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPut({
      ...props.basicPayload,
      defectId: item.defId,
      deviceUpdateDefectPayload: {
        blameId: Number(item.vinId),
        osn: Boolean(item.osn),
        primec: item.primec,
      },
    }))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          onChange(item);
        };
      });
  };

  const getBlames = () => {
    blamesHttp.request(() => blamesApi.apiDictBlamesListGet())
      .then(res => setBlames(res.data));
  };

  useEffect(() => {
    getBlames();
  }, []);

  const blamesOptions = useMemo(() => map(blames, blame => ({
    key: Number(blame.blameId),
    text: `${language === AppLanguage.Russian ? blame.nameR : blame.nameE}`,
  })), [blames, language]);

  const columns = useMemo(() => getColumns(
    language,
    blamesOptions,
    onChange,
    onBlur,
    props.readonly
  ), [language, blamesOptions]);

  useEffect(() => {
    if (Boolean(!haveChekedDefects.length) && props.defects.length === 1) onBlur({ ...props.defects[0], osn: true });
  }, [props.defects.length]);

  useEffect(() => {
    haveChekedDefects.length !== 1 && props.defects.length
      ? props.setWarningMessage(loc.warnings.chooseTheMainDefect)
      : props.setWarningMessage("");
  }, [haveChekedDefects.length, props.defects.length]);

  return (
    <div>
      <div className="table-title">
        <div>
          <DefaultButton
            className="show-table"
            iconProps={{ iconName: "Send" }}
            onClick={() => setTableIsOpen(!tableIsOpen)}
          />
          <b onClick={() => setTableIsOpen(!tableIsOpen)}> {loc.labels.defectsAndReasins}</b>
        </div>

        {tableIsOpen ? (
          <>
            <DefaultButton
              iconProps={{ iconName: "add" }}
              onClick={() => {
                props.onRowDataUpdate && props.onRowDataUpdate();
                props.setActiveCommand(CommandbarCommands.DefectsList);
              }}
              disabled={props.readonly || props.disabledAddButton}
            />
            <DefaultButton
              iconProps={{ iconName: "delete" }}
              disabled={!selectedItems.length || props.readonly}
              onClick={() => setDeleteModal(true)}
            />
          </>
        ) : null}
      </div>
      {tableIsOpen ? (
        <div className="scrollable-defects-wrapper">
          <ScrollableTableWrapper noData={!props.defects?.length}>
            <Table
              items={props.defects}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              selection={selection}
            />
          </ScrollableTableWrapper>
        </div>
      ) : null}
      <CommandWrapper isActive={deleteModal}>
        <DeleteModal
          text={loc.deviceModal.deleteDefectTitle}
          type="defect"
          payload={{ defectIds: map(selectedItems, item => item.defId) }}
          basicPayload={props.basicPayload}
          updateState={props.updateState}
          onDismiss={() => setDeleteModal(false)}
        />
      </CommandWrapper>
    </div>
  );
};
