import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { IComboBoxOption } from "@fluentui/react";
import { filter, find, map } from "lodash-es";
import { companyOptions } from "constants/options";
import { RmaRequestDeviceBrowseDto, RmaRequestDeviceFirmSelection } from "generated-sources/openapi";
import { BLACK_COLOR, GREY_COLOR } from "constants/colors";

export type CompanyCheck = {
  P: boolean;
  L: boolean;
  Y: boolean;
};

interface ICompaniesColumnHeaderProps {
  devices?: RmaRequestDeviceBrowseDto[];
  updateAllRmaFirms?: (
    checked: boolean,
    firmSelection: IComboBoxOption,
    setCompanyCheck: Dispatch<SetStateAction<CompanyCheck>>
  ) => void;
}

export const CompaniesColumnHeader = (props: ICompaniesColumnHeaderProps) => {
  const [companyCheck, setCompanyCheck] = useState<CompanyCheck>({
    L: false,
    P: false,
    Y: false,
  });

  const companyIsChecked = (company: RmaRequestDeviceFirmSelection): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkedDevices = filter(props.devices, device => (device as any)[company.toLocaleLowerCase()]);
    return props.devices?.length ? checkedDevices.length === props.devices.length : false;
  };

  useEffect(() => {
    setCompanyCheck({
      L: companyIsChecked(RmaRequestDeviceFirmSelection.Pulsar),
      P: companyIsChecked(RmaRequestDeviceFirmSelection.Polaris),
      Y: companyIsChecked(RmaRequestDeviceFirmSelection.Yukon),
    });
  }, [props.devices?.length]);

  return (
    <div>
      {map(companyOptions.slice(0, -2), (option) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isChecked = (companyCheck as any)[option.text];
        return (
          <span
            key={option.key}
            style={{
              padding: "0px 5px",
              marginRight: 5,
              // fontWeight: isChecked ? "bold" : "normal",
              // textDecoration: isChecked ? "underline" : "",
              border: `1px solid ${isChecked ? BLACK_COLOR : GREY_COLOR}`,
              borderRadius: 2,
            }}
            onClick={() => {
              const firmSelection = find(companyOptions, { text: option.text }) as IComboBoxOption;
              props.updateAllRmaFirms && props.updateAllRmaFirms(isChecked, firmSelection, setCompanyCheck);
            }}
          >
            {option?.text}
          </span>
        );
      })}
    </div>
  );
};
