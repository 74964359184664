
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicInput } from "components/common/BasicInput";
import loc from "localization";
import styles from "./styles.module.scss";
interface INotesTabProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  readonly?: boolean;
}

export const NotesTab = ({ formik, readonly }: INotesTabProps) => {
  return (
    <BasicModalColumns>
      <BasicModalColumn>
        <InfoContainer label={loc.labels.notesFromClient}>
          <BasicInput
            multiline
            name={"notes"}
            value={formik.values.notes}
            onChange={(value: string) => formik.setFieldValue("notes", value)}
            onBlur={formik.handleSubmit}
            maxLength={254}
            errorMessage={formik.errors.notes}
            disabled
          />
        </InfoContainer>
        <InfoContainer label={loc.labels.notesQc}>
          <BasicInput
            multiline
            name={"notesQc"}
            value={formik.values.notesQc}
            onChange={(value: string) => formik.setFieldValue("notesQc", value)}
            onBlur={formik.handleSubmit}
            maxLength={254}
            errorMessage={formik.errors.notesQc}
            disabled={readonly}
          />
        </InfoContainer>
      </BasicModalColumn>

      <BasicModalColumn>
        <InfoContainer infoContainerLabelClassName={styles.preWrap} label={`${loc.labels.notesForTheClient} \n only English`}>
          <BasicInput
            multiline
            name={"notesKli"}
            value={formik.values.notesKli}
            onChange={(value: string) => formik.setFieldValue("notesKli", value)}
            onBlur={formik.handleSubmit}
            errorMessage={formik.errors.notesKli}
            maxLength={254}
            disabled={readonly}
          />
        </InfoContainer>
        <InfoContainer label={loc.labels.clientContacts}>
          <BasicInput
            multiline
            name={"customerContacts"}
            value={formik.values.customerContacts}
            onChange={(value: string) => formik.setFieldValue("customerContacts", value)}
            onBlur={formik.handleSubmit}
            errorMessage={formik.errors.customerContacts}
            maxLength={254}
            disabled={readonly}
          />
        </InfoContainer>
      </BasicModalColumn>
    </BasicModalColumns>
  );
};
