import { DefaultButton } from "@fluentui/react";
import { useState } from "react";
import loc from "localization";
import { BasicInput } from "components/common/BasicInput";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicCheckbox } from "components/common/BasicCheckbox";

interface ICustomerCommunicationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  onRowDataUpdate?: () => void;
  updateDeviceInfo: (payload?: unknown) => void;
  readonly?: boolean;
}

export const CustomerCommunication = ({ formik, onRowDataUpdate, updateDeviceInfo, readonly  }: ICustomerCommunicationProps) => {
  const [tableIsOpen, setTableIsOpen] = useState<boolean>(false);
  return (
    <div>
      <div className="table-title">
        <div>
          <DefaultButton
            className="show-table"
            iconProps={{ iconName: "Send" }}
            onClick={() => setTableIsOpen(!tableIsOpen)}
          />
          <b onClick={() => setTableIsOpen(!tableIsOpen)}>{loc.deviceModal.customerCommunication}</b>
        </div>
      </div>
      {tableIsOpen ? (
        <>
          <BasicModalColumns>
            <BasicModalColumn>
              <div style={{ marginTop: 28 }}>
                <b>{loc.deviceModal.customerCommunication}</b>
                <BasicInput
                  multiline
                  name={"customerCommunication"}
                  value={formik.values.customerCommunication}
                  onChange={(value: string) => formik.setFieldValue("customerCommunication", value)}
                  errorMessage={formik.errors.customerCommunication}
                  maxLength={1000}
                  onBlur={updateDeviceInfo}
                  disabled={readonly}
                />
              </div>
            </BasicModalColumn>
          </BasicModalColumns>
          {formik.values.reControl ? (
            <BasicModalColumns>
              <BasicModalColumn>
                <InfoContainer label={loc.labels.requestClarification}>
                  <BasicCheckbox
                    checked={formik.values.requestConfirmation}
                    onChange={(checked) => {
                      formik.setFieldValue("requestConfirmation", checked);
                      formik.handleSubmit();
                      onRowDataUpdate && onRowDataUpdate();
                    }}
                    disabled={!formik.values.customerCommunication?.length}
                  />
                </InfoContainer>

              </BasicModalColumn>
              <BasicModalColumn>
                <></>
              </BasicModalColumn>
            </BasicModalColumns>
          ) : <></>}
        </>

      ) : null}
    </div>
  );
};