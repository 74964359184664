import { useApi, useHttp } from "hooks";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicInput } from "components/common/BasicInput";
import loc from "localization";
import { DefectsAndReasons } from "./DefectsAndReasons";
import { ReplacedSes } from "./ReplacedSes";
import { WorkExecuted } from "./WorkExecuted";
import {
  DeviceAddedSeDto,
  DeviceDefectsDto,
  DeviceReplacedSeDto,
  DeviceWorklistDto,
} from "generated-sources/openapi";
import { CommandbarCommands } from "constants/enums";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { AddedSes } from "./AddedSes";
import { CustomerCommunication } from "./CustomerCommunication";
import styles from "../styles.module.scss";


interface IDefectsTabProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  basicPayload: { firmId: number, deviceId: number };
  defects: DeviceDefectsDto[];
  setDefects: (callback: (defects: DeviceDefectsDto[]) => DeviceDefectsDto[]) => void;
  replacedSes: DeviceReplacedSeDto[];
  addedSes: DeviceAddedSeDto[];
  setAddedSes: (callback: (defects: DeviceAddedSeDto[]) => DeviceAddedSeDto[]) => void;
  setReplacedSes: (callback: (defects: DeviceReplacedSeDto[]) => DeviceReplacedSeDto[]) => void;
  worklists: DeviceWorklistDto[];
  setWorklists: (callback: (worklists: DeviceWorklistDto[]) => DeviceWorklistDto[]) => void;
  setActiveCommand: (command: CommandbarCommands) => void;
  updateState: () => void;
  setWarningMessage: (message: string) => void;
  onRowDataUpdate?: () => void;
  readonly?: boolean;
  specPermission?: boolean;
  otkPermission?: boolean;
  addedSesSum: number;
  replacedSesSum: number;
  confirmRepair: (val: boolean) => void;
}

export const DefectsTab = (props: IDefectsTabProps) => {
  const updateDeviceHttp = useHttp();
  const { devicesApi } = useApi();
  const { formik } = props;
  const completeControl = (field: string, value?: boolean, falseForAll?: boolean) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCompleteControlPost({
      ...props.basicPayload,
      completeControlPayload: {
        secondaryConrolQualityConfirmed: falseForAll ? false : field === "reControlC" ? Boolean(value) : formik.values.reControlC,
        secondaryConrolAnalysisConfirmed: falseForAll ? false : field === "reControlAnalysisConfirmed" ? Boolean(value) : formik.values.reControlAnalysisConfirmed,
      },
    }))
      .then(() => {
        props.onRowDataUpdate && props.onRowDataUpdate();
        if (falseForAll) {
          formik.setValues({ ...formik.values, reControlC: false, reControlAnalysisConfirmed: false });
        } else {
          formik.setFieldValue(field, value);
        };
      });
  };

  const assignControl = (field: string, value?: boolean) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAssignControlPost({
      ...props.basicPayload,
      assignControlPayload: {
        primaryConrol: field === "beControl" ? Boolean(value) : formik.values.beControl,
        secondaryConrol: field === "reControl" ? Boolean(value) : formik.values.reControl,
      },
    }))
      .then(() => {
        props.onRowDataUpdate && props.onRowDataUpdate();
        formik.setFieldValue(field, value);
      });
  };

  return (
    <>
      <BasicModalColumns>
        <BasicModalColumn>
          <b>{loc.labels.control}</b>
          <InfoContainer infoContainerClassName={styles.controlContainer} label={loc.labels.assigned}>
            <div className="flex-row">
              <BasicCheckbox
                checked={formik.values.reControl}
                onChange={(checked) => {
                  assignControl("reControl", checked);
                  if (!checked) {
                    formik.setFieldValue("requestConfirmation", false);
                    formik.handleSubmit();
                    props.onRowDataUpdate && props.onRowDataUpdate();
                  };
                }}
                disabled={!props.specPermission || props.readonly}
              />
            </div>
          </InfoContainer>
          <InfoContainer infoContainerClassName={styles.controlContainer} label={loc.labels.passed}>
            <div className="flex-row">
              <BasicCheckbox
                label={loc.labels.otkSpecialist}
                checked={formik.values.reControlC}
                onChange={(checked) => completeControl("reControlC", checked)}
                disabled={!(props.specPermission || props.otkPermission) || !formik.values.repeatedRepairConfirmed || props.readonly}
              />
              <BasicCheckbox
                label={loc.labels.analysisSpecialist}
                checked={formik.values.reControlAnalysisConfirmed}
                onChange={(checked) => completeControl("reControlAnalysisConfirmed", checked)}
                disabled={!(props.specPermission || props.otkPermission) || !formik.values.repeatedRepairConfirmed || props.readonly}
              />
            </div>
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <div className="flex-row" style={{ paddingTop: "10px" }}>
            <InfoContainer infoContainerLabelClassName={styles.width170} label={loc.labels.repairConfirmation}>
              <BasicCheckbox
                checked={formik.values.repeatedRepairConfirmed}
                onChange={(checked) => {
                  if(!checked) completeControl("", false, true);
                  formik.setFieldValue("repeatedRepairConfirmed", checked);
                  props.confirmRepair(checked);
                }}
                disabled={props.readonly}
              />

            </InfoContainer>
            <InfoContainer infoContainerLabelClassName={styles.width170} label={loc.labels.replacementToNew}>
              <BasicCheckbox
                checked={formik.values.replacementForNew}
                onChange={(checked) => {
                  formik.setFieldValue("replacementForNew", checked);
                  formik.handleSubmit();
                  props.onRowDataUpdate && props.onRowDataUpdate();
                }}
                disabled={props.readonly}
              />
            </InfoContainer>
          </div>

        </BasicModalColumn>
      </BasicModalColumns>

      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer label={loc.labels.preliminaryAnalysis}>
            <BasicInput
              name={"pAnaliz"}
              value={formik.values.pAnaliz}
              onChange={(value: string) => formik.setFieldValue("pAnaliz", value)}
              onBlur={formik.handleSubmit}
              errorMessage={formik.errors.pAnaliz}
              disabled={props.readonly}
              multiline
              maxLength={500}
            />
          </InfoContainer>
          <br />
          <InfoContainer>
            <DefectsAndReasons
              basicPayload={props.basicPayload}
              defects={props.defects}
              setDefects={props.setDefects}
              setActiveCommand={props.setActiveCommand}
              updateState={props.updateState}
              setWarningMessage={props.setWarningMessage}
              readonly={props.readonly}
              onRowDataUpdate={props.onRowDataUpdate}
              disabledAddButton={
                formik.values.repeatedRepairConfirmed
                  ? !(formik.values.reControlC && formik.values.reControlAnalysisConfirmed)
                  : false
              }
            />
          </InfoContainer>
          <br />
          <InfoContainer>
            <ReplacedSes
              basicPayload={props.basicPayload}
              replacedSes={props.replacedSes}
              setReplacedSes={props.setReplacedSes}
              addedSesSum={props.addedSesSum}
              setActiveCommand={props.setActiveCommand}
              updateState={() => {
                props.updateState();
                props.onRowDataUpdate && props.onRowDataUpdate();
              }}
              readonly={props.readonly}
              formik={formik}
            />
          </InfoContainer>
          <br />
          <InfoContainer>
            <AddedSes
              basicPayload={props.basicPayload}
              addedSes={props.addedSes}
              setAddedSes={props.setAddedSes}
              setActiveCommand={props.setActiveCommand}
              updateState={() => {
                props.updateState();
                props.onRowDataUpdate && props.onRowDataUpdate();
              }}
              readonly={props.readonly}
              formik={formik}
              replacedSesSum={props.replacedSesSum}
            />
          </InfoContainer>
          <br />
          <InfoContainer>
            <WorkExecuted
              formik={formik}
              basicPayload={props.basicPayload}
              worklists={props.worklists}
              setWorklists={props.setWorklists}
              setActiveCommand={props.setActiveCommand}
              updateDeviceInfo={formik.handleSubmit}
              updateState={props.updateState}
              readonly={props.readonly}
            />
          </InfoContainer>
          <br />
          <InfoContainer>
            <CustomerCommunication
              formik={formik}
              onRowDataUpdate={props.onRowDataUpdate}
              updateDeviceInfo={formik.handleSubmit}
              readonly={props.readonly}
            />
          </InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
    </>
  );
};
