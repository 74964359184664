import { useContext, useState, useEffect, useMemo } from "react";
import { SelectionMode } from "@fluentui/react";
import { map } from "lodash-es";
import { useApi, useHttp, useMultiSelection } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { Table } from "components/common/Table";
import { AppLanguage } from "constants/enums";
import { CompletenessItemDto } from "generated-sources/openapi";
import { getColumns } from "./config";
import styles from "../AddDefectModal/styles.module.scss";

export interface IAddEquipmentModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateState: (payload: any) => void;
  onDismiss: () => void;
}

export const AddEquipmentModal = (props: IAddEquipmentModalProps) => {
  const { dictCompleteness } = useApi();
  const devicesHttp = useHttp<CompletenessItemDto[]>();
  const { loc, language } = useContext(LanguageContext);
  const [equipments, setEquipments] = useState<CompletenessItemDto[]>([]);
  const { selection, selectedItems } = useMultiSelection<CompletenessItemDto>(equipments);

  const submitHandler = () => {
    const komplekt = map(selectedItems, (item) =>
      language === AppLanguage.Russian ? item.nameRu : item.nameEn,
    ).join(", ");
    props.formik.setFieldValue("komplekt", komplekt);
    props.updateState({ komplekt });
    props.onDismiss();
  };

  const loadEquipments = () => {
    devicesHttp
      .request(() => dictCompleteness.apiDictCompletenessBrowseGet())
      .then((res) => setEquipments(res.data));
  };

  useEffect(() => {
    loadEquipments();
  }, []);

  const columns = useMemo(() => getColumns(language), [language]);

  return (
    <BasicModal
      additionalClassName={styles.addDefectModal}
      isLoading={devicesHttp.isLoading}
      title={loc.deviceModal.addEquipmentTitle}
      errors={devicesHttp.errorMessages}
      submitButtonText={loc.buttons.add}
      dismissButtonText={loc.buttons.close}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <Table
          items={equipments}
          columns={columns}
          selection={selection}
          selectionMode={SelectionMode.multiple}
        />
      </BasicModalContent>
    </BasicModal>
  );
};
