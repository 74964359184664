import styles from "./styles.module.scss";
import { IComboBoxOption, TooltipHost } from "@fluentui/react";
import { useApi, useHttp } from "hooks";
import { find } from "lodash-es";
import { allCompaniesOptions, companyOptions } from "constants/options";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { DatePickerInput } from "components/common/DatePickerInput";
import { BasicDropdown } from "components/common/BasicDropdown";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { useContext } from "react";
import { LanguageContext } from "context/languageContext";
import { AuthContext } from "context/authContext";
import loc from "localization";

interface IMainBlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  basicPayload: { firmId: number, deviceId: number };
  readonly?: boolean;
  updateState?: () => void;
};

export const MainBlock = ({ formik, basicPayload, readonly, updateState }: IMainBlockProps) => {
  const { company } = useContext(AuthContext);
  const { devicesApi } = useApi();
  const updateDeviceHttp = useHttp();
  const { language } = useContext(LanguageContext);
  const userCompanyId = Number(find(companyOptions, { text: company })?.key);
  const setWarranty = (value: boolean) => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdGeneralSetWarrantyPost({
      deviceId: basicPayload.deviceId,
      deviceFirmId: basicPayload.firmId,
      setWarrantyPayload: {
        enableWarranty: Boolean(value),
        firmId: userCompanyId
      },
    }))
      .then(() => formik.setFieldValue("warranty", value));
  };

  const cancelInvoice = () => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost({
      ...basicPayload
    }))
      .then(() => {
        formik.setFieldValue("stoimRem", 0);
        formik.setFieldValue("schet", false);
        formik.handleSubmit();
      });
  };

  return (
    <>
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer infoContainerValueClassName={styles.underlinedValue} label={loc.columns.se}>{formik.values.seK || "-"}</InfoContainer>
          <InfoContainer infoContainerValueClassName={styles.underlinedValue} label={loc.columns.sku}>{formik.values.sku || "-"}</InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <InfoContainer infoContainerValueClassName={styles.underlinedValue} label={loc.columns.serialN}>{formik.values.nomer || "-"}</InfoContainer>
          <InfoContainer infoContainerValueClassName={styles.underlinedValue} label={loc.columns.serialOld}>{formik.values.oldNomer || "-"}</InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
      {/* SECTION 2 */}
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer infoContainerValueClassName={styles.nameInfoContainer} label={loc.columns.name}>
            {language === "ru" ? (
              <TooltipHost content={formik.values.nameRu}>
                {formik.values.nameRu || "-"}
              </TooltipHost>
            ) : (
              <TooltipHost content={formik.values.nameEn}>
                {formik.values.nameEn || "-"}
              </TooltipHost>
            )}
          </InfoContainer>
          <InfoContainer label={loc.columns.distributor}>
            <TooltipHost content={formik.values.konName}>{formik.values.konName || "-"}</TooltipHost>
          </InfoContainer>
          <InfoContainer label={loc.columns.buyer}>
            <TooltipHost content={formik.values.buyer}>{formik.values.buyer || "-"}</TooltipHost>
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <InfoContainer label={loc.columns.productionDate}>
            <div className="flex-row">
              <DatePickerInput
                name={"dataProiz"}
                value={formik.values.dataProiz}
                onChangeValue={formik.setFieldValue}
                onBlur={formik.handleSubmit}
                errorMessage={formik.errors.dataProiz}
                disabled={readonly}
              />
              <BasicDropdown
                clearButtonOff
                itemKey={formik.values.proiz}
                options={allCompaniesOptions}
                errorMessage={formik.errors.proiz}
                onChange={(_, option?: IComboBoxOption) => {
                  formik.setFieldValue("proiz", option?.key);
                  formik.handleSubmit({ proiz: option?.key });
                  updateState && updateState();
                }}
                disabled={readonly}
              />
            </div>
          </InfoContainer>
          <InfoContainer label={loc.labels.saleDate}>
            <DatePickerInput
              name={"dataProd"}
              value={formik.values.dataProd}
              onChangeValue={formik.setFieldValue}
              onBlur={formik.handleSubmit}
              errorMessage={formik.errors.dataProd}
              disabled={readonly}
            />
          </InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
      {/* SECTION 3 */}
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer>
            <BasicCheckbox
              label={loc.legend.speedRepair}
              checked={formik.values.speedRepair}
              onChange={(checked) => {
                updateState && updateState();
                formik.setFieldValue("speedRepair", checked);
                formik.handleSubmit({ speedRepair: checked });
              }}
              disabled={readonly}
            />
          </InfoContainer>
          <InfoContainer>
            <BasicCheckbox
              label={loc.columns.defectIsConfirmed}
              checked={formik.values.defectConfirmed}
              onChange={(checked) => {
                updateState && updateState();
                formik.setFieldValue("defectConfirmed", checked);
                formik.handleSubmit({ defectConfirmed: checked });
              }}
              disabled={readonly}
            />
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <InfoContainer>
            <BasicCheckbox
              label={loc.labels.warranty}
              checked={formik.values.warranty}
              onChange={(checked) => {
                updateState && updateState();
                formik.setValues({
                  ...formik.values,
                  warranty: checked,
                  schet: !checked ? true : formik.values.schet,
                });
                setWarranty(checked);
                if (checked && formik.values.firmId !== 6) cancelInvoice();
              }}
              disabled={readonly}
            />
          </InfoContainer>
          <InfoContainer>
            <BasicCheckbox
              label={loc.columns.returnToTheOwner}
              checked={formik.values.wait}
              onChange={(checked) => {
                updateState && updateState();
                formik.setFieldValue("wait", checked);
                formik.handleSubmit({ wait: checked });
              }}
              disabled={readonly}
            />
          </InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
    </>
  );
};
